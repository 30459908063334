import { useQuery } from "@tanstack/react-query";
import { axios } from "lib/axios";

export const getSuperAdminStats = ({
  page,
  pageSize,
  sortBy,
  sortDirection
}) => {
  return axios.get(
    `/dashboard/super-admin-stats?page=${page}&per_page=${pageSize}&sort_by=${sortBy}&sort_direction=${sortDirection}`
  );
};

export const useSuperAdminStats = ({
  page,
  pageSize,
  sortBy,
  sortDirection,
  config
} = {}) => {
  return useQuery({
    ...config,
    queryKey: ["super-admin-stats", page, pageSize, sortBy, sortDirection],
    queryFn: () =>
      getSuperAdminStats({ page, pageSize, sortBy, sortDirection }),
    select: response => response.result
  });
};
